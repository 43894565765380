<template>
    <div class="container">
        <vs-button type="flat" icon-pack="feather" @click="go()">
            <table class="tab box-shadow-1 " id="tbl" style="width:100%" >
                <tr >
                    <td class="ligne" :style="colors()" align="left" id="gauchehaut">  {{ this.cours }} </td>
                    <td class="deuxieme" align="right" :style="colors()" id="droitehaut"> Debut: {{ this.debut}}  </td>
                </tr>
                <tr >
                    <td class="ligne" style="font-weight:bold" :style="colors()" align="left"> Coach: {{ this.coach }} </td>
                    <td class="deuxieme" align="right" :style="colors()"> Fin: {{ this.fin}} </td>
                </tr>
                <tr class="border_bottom">
                    <td class="lignes" id="gauchebas">  {{ this.categorie }} </td>
                    <td class="deuxiemes" align="right" id="droitebas"> participant(s): {{ this.participant}}  </td>
                </tr>
            </table>
        </vs-button>
            <br>
        
       
        
    </div>
  
</template>

<script>
import VxCard from '../vx-card/VxCard.vue';
export default {
  components: { VxCard },
    name:'group',
    props:{
        coach:"",
        categorie:"",
        cours:"",
        debut:"",
        fin:"",
        participant:0,
        color:"",
        dates:"",
        uid:"",
        

            
    },

    watch:{
        coach:function(){
            //console.log(" coach : ", this.coach)
        },
        date:function(){
           // console.log(" date changed ")
        }
    },
    methods:{
        update(co,cat,cou,deb,fin,par){
            this.coach=co;
            this.categorie=cat;
            this.cours=cou;
            this.debut=deb;
            this.fin=fin;
            this.participant=par;
        },
        updateDate(dat){
            this.date=dat;
        },
        test(){
           // console.log(" oook")
        },
        colors(){
            //console.log(" -- > ", this.color)
            
            return "background:"+this.color
        },
        go(){
            let d = this.dates;
            let fd = ( d.getMonth()+1)+'-'+d.getDate()+'-'+d.getFullYear();
            
            this.$router.push({path:'/admin/calling/',query: {date:fd, uid:this.uid}}).catch(()=>{})
            // this.$router.push('/admin/calling/'+fd)
        }
    }


}
</script>

<style>

.ligne{
    padding-left: 2%;
    padding-top:1%;
    padding-bottom:1%;
    
    color:white;
}

.deuxieme{
    
    padding-right: 2%;
    padding-top:1%;
    padding-bottom:1%;
    
    color:white;
    
}

.lignes{
    padding-left: 2%;
    padding-top:1%;
    padding-bottom:1%;
    background: white;
    
}

.deuxiemes{
    
    padding-right: 2%;
    padding-top:1%;
    padding-bottom:1%;
    background: white;
    
}

.tab{
    border-collapse: collapse;
    /* border: 1px solid #EEEEEE;  */
     
}

#droitehaut{
    border-top-right-radius: 10px 10px;
}

#droitebas{
    border-bottom-right-radius: 10px 10px;
}

#gauchehaut{
    border-top-left-radius: 10px 10px;
}
#gauchebas{
    border-bottom-left-radius: 10px 10px ;
   
    
}

#tbl{
   
    border-radius: 10px;
    box-shadow: 10px 10px 5px #EAEAEA;
}




</style>